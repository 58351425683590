// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  if ($('#special-matsuzaka').length) {
    // 2023.1.5 大島
    // 特設サイト「松阪牛」のメニュー表示のイベントトラッキング
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'Navi', 'Impression', '17:Main:Top', '1');
    }
  }
});
