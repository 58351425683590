// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  //
  if ($('.side_content_ai_recommend_spot').length) {
    $.ajax({
      url: '/side_content_ai_recommend',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  if ($('.side_content_ai_item_recommend_spot').length) {
    const content_id = $('.side_content_ai_item_recommend_spot').data('contentId');
    $.ajax({
      url: '/side_content_ai_item_recommend_spot',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      data: { id: content_id },
    });
  }

  if ($('.side_content_ai_item_recommend_event').length) {
    const content_id = $('.side_content_ai_item_recommend_event').data('contentId');
    $.ajax({
      url: '/side_content_ai_item_recommend_event',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      data: { id: content_id },
    });
  }

  if ($('.side_content_ai_item_recommend_activity').length) {
    const content_id = $('.side_content_ai_item_recommend_activity').data('contentId');
    $.ajax({
      url: '/side_content_ai_item_recommend_activity',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      data: { id: content_id },
    });
  }

  if ($('.side_content_ai_item_recommend_report').length) {
    const content_id = $('.side_content_ai_item_recommend_report').data('contentId');
    $.ajax({
      url: '/side_content_ai_item_recommend_report',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      data: { id: content_id },
    });
  }

  if ($('.side_content_ai_item_recommend_feature_page').length) {
    const content_id = $('.side_content_ai_item_recommend_feature_page').data('contentId');
    $.ajax({
      url: '/side_content_ai_item_recommend_feature_page',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      data: { id: content_id },
    });
  }
});
