// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  if ($('#crm_user_token').length) {
    // CRMのユーザートークンをformの項目に追加
    document.getElementById("crm_user_token").value = localStorage.getItem("customerconcierge-token");
  }

  if ($('#user_account_form').length) {
    // 登録フォーム画面での入力パスワードチェック処理
    const form = document.getElementById('user_account_form');
    const passwordInput = document.getElementById('mypage_account_password_input');
    const confirmPasswordInput = document.getElementById('mypage_account_password_conf_input');

    if (passwordInput && confirmPasswordInput) {
      form.addEventListener('submit', function(event) {
        if (passwordInput.value !== confirmPasswordInput.value) {
          alert('"パスワード"と"パスワード確認用"の入力内容が異なります。同じ内容を設定してください。');
          event.preventDefault(); // フォームの送信をキャンセル
        }
      });
    };
  }
});
