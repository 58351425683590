import CheckboxGroup from "./_CheckboxGroup";

class AreaCheckboxGroup extends CheckboxGroup {
  constructor({ parentElm, parentSelectorName, app }) {
    super({ parentElm, parentSelectorName, app });

    this.parentElm.addEventListener("click", () => {
      this.updateAreaIdsState();
    });

    this.childElms.forEach((elm) => {
      elm.addEventListener("click", () => {
        this.updateAreaIdsState();
      });
    });
  }

  updateAreaIdsState() {
    if (this.parentElm.checked) this.app.state.activeAreaIds.push(this.groupId);
    if (!this.parentElm.checked)
      this.app.state.activeAreaIds = this.app.state.activeAreaIds.filter(
        (id) => id !== this.groupId
      );

    this.app.render();
  }

  render() {
    const isActiveArea = () =>
      this.app.state.activeAreaIds.find((id) => this.groupId === id);
    const hasUncheckedChildMember = () =>
      Array.from(this.childElms).find((elm) => elm.checked === false);

    if (isActiveArea()) {
      this.activeParent();
      this.activeChildren();
    }

    if (!isActiveArea()) {
      this.inactiveParent();
    }

    // [note]
    // エリアが「非アクティブ」である時、
    // 同一のエリアに属する子checkboxは、もれなくチェックが入っている場合のみ
    // 連動して非アクティブとなる。
    if (!isActiveArea() && !hasUncheckedChildMember()) {
      this.inactiveChildren();
    }
  }
}

export default AreaCheckboxGroup;
