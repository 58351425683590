// SwiperCmn
//

import Swiper from 'swiper/bundle';

class SwiperCmn {
  constructor({ elm }) {
    this.swiper = new Swiper(elm, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
      navigation: {
        nextEl: elm.querySelector("[data-role='cmn-swiper-next']"),
        prevEl: elm.querySelector("[data-role='cmn-swiper-prev']"),
      },
    });
  }
}

export default SwiperCmn;
