import Swiper from 'swiper/bundle';

class hayawakariMv {
  constructor() {
    this.elm = document.querySelector("[data-role='hayawakari-swiper']");

    this.hayawakarMvSwiper = new Swiper(this.elm, {
      slidesPerView: 1.05,
      spaceBetween: 12,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      watchSlidesProgress: true,
    });
  }
}

export default hayawakariMv;
