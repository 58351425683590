// ArchiveFilter
//
// Version: v 0.1.0
// Written: Masaaki Sumimoto
//
// アーカイブ一覧ページにおける絞り込みUIに対応したスクリプト。
// (本パーツは1ページに1つしか存在しない想定で記述)

import ContentCloser from './_ContentCloser';
import ContentWrap from './_ContentWrap';
import Content from './_Content';
import Selector from './_Selector';
import CheckboxGroup from './_CheckboxGroup';
import AreaPartialMapImg from './_AreaPartialMapImg';
import AreaImagemapArea from './_AreaImagemapArea';
import AreaListBtn from './_AreaListBtn';
import AreaCheckboxGroup from './_AreaCheckboxGroup';
import ImagemapResizer from './_ImagemapResizer';

class ArchiveFilter {
  constructor() {
    // Setting
    //

    this.state = {
      activeContentId: null,
      hideClassName: null,
      isContentActive: false,
      activeAreaIds: [],
    };

    this.elm = document.querySelector('[data-role="archive-filter"]');

    // Elements
    //

    this.selectors = [];
    document.querySelectorAll('[data-role="archive-filter-selector"]').forEach((selector) => {
      this.selectors.push(new Selector({ elm: selector, app: this }));
    });

    this.contentCloser = new ContentCloser({
      elm: document.querySelector('[data-role="archive-filter-content-closer"]'),
      app: this,
    });

    this.contentWrap = new ContentWrap({
      elm: document.querySelector('[data-role="archive-filter-content-wrap"]'),
      app: this,
    });

    this.contents = [];
    document.querySelectorAll('[data-role="archive-filter-content"]').forEach((content) => {
      this.contents.push(new Content({ elm: content, app: this }));
    });

    this.checkboxGroups = [];
    document.querySelectorAll('[data-role="archive-filter-parent-ck"]').forEach((parentCk) => {
      this.checkboxGroups.push(
        new CheckboxGroup({
          parentElm: parentCk,
          parentSelectorName: '[data-role="archive-filter-parent-ck"]',
          app: this,
        })
      );
    });

    // Area Filter Elements
    //

    this.areaPartialMapImgs = [];
    document.querySelectorAll('[data-role="archive-filter-area-partial-map-img"]').forEach((slice) => {
      this.areaPartialMapImgs.push(
        new AreaPartialMapImg({
          elm: slice,
          app: this,
        })
      );
    });

    this.areaImagemapAreas = [];
    document.querySelectorAll('[data-role="archive-filter-area-imagemap-area"]').forEach((btn) => {
      this.areaImagemapAreas.push(
        new AreaImagemapArea({
          elm: btn,
          app: this,
        })
      );
    });

    this.areaListBtns = [];
    document.querySelectorAll('[data-role="archive-filter-area-btn"]').forEach((btn) => {
      this.areaListBtns.push(
        new AreaListBtn({
          elm: btn,
          app: this,
        })
      );
    });

    this.areaCheckboxGroups = [];
    document.querySelectorAll('[data-role="archive-filter-area-parent-ck"]').forEach((parentCk) => {
      this.areaCheckboxGroups.push(
        new AreaCheckboxGroup({
          parentElm: parentCk,
          parentSelectorName: '[data-role="archive-filter-area-parent-ck"]',
          app: this,
        })
      );
    });

    this.imagemapResizer = new ImagemapResizer({ app: this });

    // init
    //

    this.init();

    window.addEventListener('resize', () => {
      this.imagemapResizer.render();
    });
  }

  init() {
    this.state.hideClassName = this.elm.dataset.hideClassName;
    this.areaCheckboxGroups.forEach((ckGroup) => {
      if (ckGroup.parentElm.checked === true) {
        this.state.activeAreaIds.push(ckGroup.groupId);
      }
    });

    this.render();
  }

  render() {
    this.contentWrap.render();

    this.selectors.forEach((selector) => {
      selector.render();
    });

    this.contents.forEach((content) => {
      content.render();
    });

    this.areaImagemapAreas.forEach((area) => {
      area.render();
    });

    this.areaPartialMapImgs.forEach((img) => {
      img.render();
    });

    this.areaListBtns.forEach((btn) => {
      btn.render();
    });

    this.areaCheckboxGroups.forEach((ckGroup) => {
      ckGroup.render();
    });

    // getBoundingClientRectが正しく要素の高さを取得するためやや遅延させる
    setTimeout(() => {
      this.imagemapResizer.render();
    }, 300);
  }
}

export default ArchiveFilter;
