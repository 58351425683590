import AicUi from './AicUi/App';

// ------------------------------
// For Concierge Index Page
// ------------------------------

if (document.querySelector('[data-mp-role="aic-form"]')) {
  // eslint-disable-next-line no-unused-vars
  const aicUi = new AicUi({
    aicAPIBaseURI: `/course/concierge/index.js`,
    apiKey: 'd0wI2Hldi2itkfCPzR4j', // dummy
    place: 'kanko_mie', // dummy
  });
}
