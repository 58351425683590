export default class GlobalFooter {
  constructor() {
    // [hard cord]
    // 状態をデータ管理しない。DOMの状態から適切な挙動を判別

    this.elm = document.querySelector('[data-role="miepref-footer"]');
    this.menuOpeners = document.querySelectorAll('[data-role="miepref-footer-menu-opener"]');

    Array.from(this.menuOpeners).forEach((elm) => {
      elm.addEventListener('click', () => {
        const opener = elm;
        const content = opener.nextElementSibling;
        const isSpDesign = getComputedStyle(elm.querySelector('img')).getPropertyValue('display') === 'block';

        if (isSpDesign) {
          opener.dataset.state === 'active' ? delete opener.dataset.state : (opener.dataset.state = 'active');
          content.dataset.state === 'active' ? delete content.dataset.state : (content.dataset.state = 'active');
        } else {
          return false;
        }
      });
    });
  }
}
