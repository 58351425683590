// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  const START_LAT = 34.73402180789384;
  const START_LNG = 136.51022286856377;
  let marker;

  function initMap() {
    // マップの初期化
    const map = new google.maps.Map(document.getElementById('lat_lng_map_field'), {
      zoom: 13,
      center: {lat: START_LAT, lng: START_LNG }
    });

    // クリックイベントを追加
    map.addListener('click', function(e) {
      getClickLatLng(e.latLng, map);
    });

    // 住所検索ボタンをクリックされたらジオコーディング
    $("#map_search_btn").on('click', function () {
      let address = document.getElementById('map_address').value
      geocodeAddress(address, map)
    });
  };

  function getClickLatLng(lat_lng, map) {
    // 座標を表示
    document.getElementById('photocon_photocon_image_attributes_inq_lat').value = lat_lng.lat();
    document.getElementById('photocon_photocon_image_attributes_inq_lng').value = lat_lng.lng();
    // マーカーを設置
    if (marker == undefined){
      marker = new google.maps.Marker({
        position: lat_lng,
        map: map
      })
    } else {
        marker.setPosition(lat_lng)
    };
    // マップの中心を移動
    map.panTo(lat_lng);
  };

  function geocodeAddress(address, map) {
    // photo_contestsコントローラーのgeocodingアクションへ投げる
    $.ajax({
      url: "geocoding",
      type: "GET",
      data: { address: address },
      dataType: "html",
    }).done(function (data) {
      var data_json = JSON.parse(data);
      // 地図に反映
      var lat = parseFloat(data_json["lat"])
      var lng = parseFloat(data_json["lng"])
      let latLng = new google.maps.LatLng(lat, lng)
      getClickLatLng(latLng, map)
    });
  };

  if ($('#lat_lng_map_field').length) {
    initMap()
  };
});
