// Shared Components
import GlobalHeader from './components/GlobalHeader';
import GlobalFooter from './components/GlobalFooter';
import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter';
import Tab from './components/Tab';
import SwiperCmn from './components/SwiperCmn';
import SwiperSingle from './components/SwiperSingle';
import SwiperCircle from './components/SwiperCircle';
import SwiperGallery from './components/SwiperGallery';
import SwiperPhoto from './components/SwiperPhoto';
import IndexMarkersMap from './components/IndexMarkersMap';
import checkAgreeBtn from './components/form';
import FlowerParam from './components/FlowerParam/FlowerParam';
import RecommendContentsPopup from './components/RecommendContentsPopup';

// Page Components
import TopMv from './components/TopMv';
import TopMap from './components/TopMap/TopMap';
import hayawakariMv from './components/hayawakariMv';

// Global Header & Footer
//

if (document.querySelector('[data-role="miepref-header"]')) {
  new GlobalHeader();
}

if (document.querySelector('[data-role="miepref-footer"]')) {
  new GlobalFooter();
}

// ArchiveFilter
//
// アーカイブ一覧ページにおける絞り込みフォーム要素パーツ

if (document.querySelector('[data-role="archive-filter"]')) {
  // eslint-disable-next-line no-new
  new ArchiveFilter();
}

// Tab
//
// ページに存在するdata-role=tab-navの数ぶん生成

if (
  document.querySelectorAll('[data-role="tablist"]').length &&
  document.querySelectorAll('[data-role="tab-contents"]').length
) {
  document.querySelectorAll('[data-role="tablist"]').forEach((tablist) => {
    // eslint-disable-next-line no-new
    new Tab({ tablist });
  });
}

// Carousel
//
// 汎用 Swiper要素4種類

const cmnSwiperElms = document.querySelectorAll("[data-role='cmn-swiper']");
if (cmnSwiperElms.length) {
  cmnSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperCmn({ elm });
  });
}

const singleSwiperElms = document.querySelectorAll("[data-role='single-swiper']");
if (singleSwiperElms.length) {
  singleSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSingle({ elm });
  });
}

const circleSwiperElms = document.querySelectorAll("[data-role='circle-swiper']");
if (circleSwiperElms.length) {
  circleSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperCircle({ elm });
  });
}

const photoSwiperElms = document.querySelectorAll("[data-role='photo-swiper']");
if (photoSwiperElms.length) {
  photoSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperPhoto({ elm });
  });
}

const gallerySwiperElms = document.querySelectorAll('[data-role="gallery-swiper"]');
if (gallerySwiperElms.length) {
  gallerySwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperGallery({ pairingId: elm.dataset.pairingId });
  });
}

// form プライバシーポリシー同意
const agreePrivacyPolicyElms = document.querySelectorAll('[data-role="agree-privacy-policy"]');
if (agreePrivacyPolicyElms.length) {
  agreePrivacyPolicyElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new checkAgreeBtn({ elm });
  });
}

// トップページ - MV
//
// swiperをベースにデザイン表現を拡張

if (document.querySelector("[data-role='top-mv-swiper']")) {
  // eslint-disable-next-line no-new
  new TopMv();
}

// トップページ - map
//

if (document.querySelector('[data-role="top-map"]')) {
  // eslint-disable-next-line no-new
  new TopMap({ activeAreaId: 'area1', mapSelectScrollTarget: document.querySelector('.area-map-scroll-target') });
}

// Index Markers Map
//
// 特定の詳細記事内部に配置される

const btnMap = document.querySelector('[data-role="btn-map"]');

if (btnMap) {
  btnMap.addEventListener('click', () => {
    const indexMarkersMaps = document.querySelector('[data-role="index-markers-map"]');
    // eslint-disable-next-line no-new
    new IndexMarkersMap({ elm: indexMarkersMaps });
    btnMap.classList.add('d-none');
    indexMarkersMaps.classList.remove('d-none');
    indexMarkersMaps.classList.add('d-block');
  });
}

// 三重の見どころ 早わかり - MV
//
if (document.querySelector("[data-role='hayawakari-swiper']")) {
  // eslint-disable-next-line no-new
  new hayawakariMv();
}

// Flower Param
//
const flowerParams = document.querySelectorAll('[data-role="flower-param"]');
if (flowerParams.length) {
  flowerParams.forEach((elm) => {
    // eslint-disable-next-line no-new
    new FlowerParam({ elm });
  });
}

const inpuDateFields = document.querySelectorAll('input[type="date"]');
if (inpuDateFields.length) {
  inpuDateFields.forEach((inputDate) => {
    inputDate.style.background = '#fff';
    inputDate.style.display = 'flex';
    inputDate.style.width = 'calc(100vw - 7rem - 20px)';
  });
}

/**
 * header内のカスタム検索に文字、BackSpaceを入力すると上に自動スクロールされる動作を解消させる
 */

// 監視対象の要素を選択
const targetNode = document.querySelector('.miepref-global-header-g-search');

if (targetNode !== null) {
  // オプションを設定
  const config = { childList: true, subtree: true };

  // 入力時およびバックスペース時のスクロールを制御する関数
  const preventScrollOnInput = (inputElement) => {
    let scrollPosition;

    const handleScroll = () => {
      // 入力が完了した後、スクロール位置を元に戻す
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 0);
    };

    // 入力イベントリスナー
    inputElement.addEventListener('input', () => {
      // 現在のスクロール位置を保存
      scrollPosition = window.pageYOffset;
      // スクロール位置を制御
      handleScroll();
    });

    // キーダウンイベントリスナー
    inputElement.addEventListener('keydown', (event) => {
      // バックスペースキーが押された場合
      if (event.key === 'Backspace') {
        // 現在のスクロール位置を保存
        scrollPosition = window.pageYOffset;
        // スクロール位置を制御
        handleScroll();
      }
    });
  };

  // MutationObserverのコールバック関数
  const callback = (mutationsList, observer) => {
    for (const mutation of mutationsList) {
      // 子要素が変更された場合
      if (mutation.type === 'childList') {
        // 追加されたノードをチェック
        for (const addedNode of mutation.addedNodes) {
          // input[type="text"]が追加された場合
          if (addedNode.nodeName === 'INPUT' && addedNode.type === 'text') {
            // 入力時およびバックスペース時のスクロールを制御
            preventScrollOnInput(addedNode);
            // input[type="text"]が見つかったので、監視を終了
            observer.disconnect();
          }
        }
      }
    }
  };

  // MutationObserverをインスタンス化
  const observer = new MutationObserver(callback);

  // DOM変更の監視を開始
  observer.observe(targetNode, config);
}

// 画像の保存禁止簡易処理
function imageGuard(selector) {
  const guard_selector = document.querySelectorAll(selector);
  for (let n = 0; n < guard_selector.length; n++) {
    guard_selector[n].addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      false
    );
  }
  const guard_style = {
    'pointer-events': 'none',
    '-webkit-touch-callout': 'none',
    '-moz-touch-callout': 'none',
    'touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    'user-select': 'none',
  };
  Object.keys(guard_style).forEach((v, i, a) => {
    for (let n = 0; n < guard_selector.length; n++) {
      guard_selector[n].style[v] = guard_style[v];
    }
  });
}
window.addEventListener('load', function () {
  imageGuard('.copy-guard');
});

// Mediaページのカート追加ボタン
//
const mediaCartBtns = document.querySelectorAll('.media-cart-btns');

async function cartRelatedAction(url, freePhotoID) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  // リクエストのボディに含めるデータ
  const data = {
    freePhotoID: freePhotoID
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include', // Cookieをリクエストに含める
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken, // CSRFトークンをヘッダーに含める
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorMessage = await response.json();
      alert(errorMessage.message); // エラーメッセージをalertで表示
      return false; // 関数からfalseを返却
    }
    // レスポンスが正常（2xx）の場合
    const responseData = await response.json();
    console.log('Success:', responseData);
    return true; // 関数からtrueを返却
  } catch (error) {
    console.error('Fetch error:', error);
    return false; // 例外が発生した場合にfalseを返却
  }
}

if (mediaCartBtns.length) {
  const mediaCartBtnsConfig = {
    addBtnIconClassName: 'fa-cart-shopping',
    addBtnHvrIconClassName: 'fa-download',
    addedBtnTxt: 'カートに追加済み',
    addedBtnHvrTxt: 'カートから削除',
    addedBtnIconClassName: 'fa-check',
    addedBtnHvrIconClassName: 'fa-xmark',
  };

  mediaCartBtns.forEach((btns) => {
    const addBtn = btns.querySelector('.media-add-cart-btn');
    const addBtnIcon = addBtn.querySelector('.media-add-cart-btn__icon');

    const addedBtn = btns.querySelector('.media-added-cart-btn');
    const addedBtnIcon = addedBtn.querySelector('.media-added-cart-btn__icon');
    const addedBtnTxt = addedBtn.querySelector('.media-added-cart-btn__txt');

    addBtn.addEventListener('click', () => {
      const freePhotoID = addBtn.id.replace('addToCart', '');
      cartRelatedAction('/media/photo_free/add_to_cart', freePhotoID).then(result => {
        if (result){
          addedBtn.classList.remove('d-none');
          addBtn.classList.add('d-none');
        }
      })
    });

    addedBtn.addEventListener('click', () => {
      const freePhotoID = addedBtn.id.replace('removeFromCart', '');
      cartRelatedAction('/media/photo_free/remove_from_cart', freePhotoID).then(result => {
        if (result) {
          addBtn.classList.remove('d-none');
          addedBtn.classList.add('d-none');
        }
      });
     });

    addBtn.addEventListener('mouseover', () => {
      addBtnIcon.classList.remove(mediaCartBtnsConfig.addBtnIconClassName);
      addBtnIcon.classList.add(mediaCartBtnsConfig.addBtnHvrIconClassName);
    });

    addBtn.addEventListener('mouseout', () => {
      addBtnIcon.classList.remove(mediaCartBtnsConfig.addBtnHvrIconClassName);
      addBtnIcon.classList.add(mediaCartBtnsConfig.addBtnIconClassName);
    });

    addedBtn.addEventListener('mouseover', () => {
      addedBtnTxt.textContent = mediaCartBtnsConfig.addedBtnHvrTxt;
      addedBtnIcon.classList.remove(mediaCartBtnsConfig.addedBtnIconClassName);
      addedBtnIcon.classList.add(mediaCartBtnsConfig.addedBtnHvrIconClassName);
    });

    addedBtn.addEventListener('mouseout', () => {
      addedBtnTxt.textContent = mediaCartBtnsConfig.addedBtnTxt;
      addedBtnIcon.classList.remove(mediaCartBtnsConfig.addedBtnHvrIconClassName);
      addedBtnIcon.classList.add(mediaCartBtnsConfig.addedBtnIconClassName);
    });
  });
}


// websサイト最下部に表示されるリコメンドポップアップ

if (document.querySelector('.recommend-contents-popup-carousel')) {
  const elm = document.querySelector('.recommend-contents-popup');
  const swiperElm = document.querySelector('[data-role="recommend-contents-popup-carousel-swiper"]');
  const handle = document.querySelector('.recommend-contents-popup__handle');
  const carouselPrev = document.querySelector('.recommend-contents-popup-carousel__nav.--prev');
  const carouselNext = document.querySelector('.recommend-contents-popup-carousel__nav.--next');
  const state = {
    isOpen: true,
  };
  new RecommendContentsPopup({ elm, swiperElm, handle, carouselPrev, carouselNext, state });
}
