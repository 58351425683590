import Swiper from 'swiper/bundle';

class TopMv {
  constructor() {
    this.topMvThumbSwiper = new Swiper("[data-role='top-mv-thumb-swiper']", {
      slidesPerView: 2.5,
      centeredSlides: true,
      spaceBetween: 8,
      loop: true,
    });

    this.elm = document.querySelector("[data-role='top-mv-swiper']");

    this.topMvSwiper = new Swiper(this.elm, {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      loop: true,
      watchSlidesProgress: true,
      pagination: {
        el: "[data-role='top-mv-swiper-pagination']",
        clickable: true,
      },
      navigation: {
        nextEl: "[data-role='top-mv-swiper-next']",
        prevEl: "[data-role='top-mv-swiper-prev']",
      },
      thumbs: {
        swiper: this.topMvThumbSwiper,
      },
    });

    this.thumbItems = document.querySelectorAll('[data-role="top-mv-thumb-item"]');
    this.topMvSwiper.on('slideChange', () => {
      this.thumbItems.forEach((thumb, index) => {
        const elm = thumb;
        if (this.topMvSwiper.realIndex === index) elm.dataset.state = 'active';
        if (this.topMvSwiper.realIndex !== index) delete elm.dataset.state;
      });
    });
  }
}

export default TopMv;
