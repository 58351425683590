import Swiper from 'swiper/bundle';

class CardSwiper {
  constructor({ elm, app, prevElm, nextElm }) {
    this.elm = elm;
    this.prevElm = prevElm;
    this.nextElm = nextElm;
    this.app = app;

    this.swiper = new Swiper(this.elm, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: false,
      navigation: {
        nextEl: nextElm,
        prevEl: prevElm,
      },
    });

    this.swiper.on('slideChange', () => {
      const areaId = this.swiper.slides[this.swiper.activeIndex].dataset.areaId;
      this.app.state.activeAreaId = areaId;
      this.app.render();
    });
  }

  render() {
    const activeSwiperSlide = Array.from(this.swiper.slides).findIndex(
      (slide) => slide.dataset.areaId === this.app.state.activeAreaId
    );
    this.swiper.slideTo(activeSwiperSlide);
  }
}

export default CardSwiper;
