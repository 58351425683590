export default class GlobalHeader {
  constructor() {
    this.header = document.querySelector('[data-role="miepref-header"]');
    this.contents = document.querySelector('[data-role="miepref-header-contents"]');
    this.opener = document.querySelector('[data-role="miepref-header-opener"]');
    this.gSearch = document.querySelector('[data-role="miepref-header-g-search"]');

    this.state = {
      isSpOpen: false,
      pageYpos: 0,
    };

    this.opener.addEventListener('click', () => {
      if (!this.state.isSpOpen) this.state.pageYpos = window.pageYOffset;
      this.state.isSpOpen = !this.state.isSpOpen;
      this.renderAll();
      return false;
    });

    window.addEventListener(this.getResponsiveEventName(), () => {
      if (this.state.isSpOpen) {
        this.state.isSpOpen = false;
        this.renderAll();
      }
    });

    window.addEventListener('load', () => {
      this.init();
    });
  }

  init() {
    // Googleカスタム検索のプレースホルダーだけ処理。
    // ただし本パーツはたびくらなどの場合存在しないため制御構文を用いておく
    const inputElm = this.gSearch.querySelector('#gsc-i-id1');
    if (inputElm) {
      inputElm.placeholder = '検索キーワードを入力してください';
    }
  }

  getResponsiveEventName() {
    const ua = navigator.userAgent;
    if (
      (ua.indexOf('iPhone') > 0 && ua.indexOf('iPad') === -1) ||
      ua.indexOf('iPod') > 0 ||
      ua.indexOf('Android') > 0
    ) {
      return 'orientationchange';
    }
    return 'resize';
  }

  fixBody() {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    html.style.overflow = 'hidden';
    body.style.overflow = 'hidden';
    html.style.height = '100%';
    body.style.height = '100%';
    html.style.scrollBehavior = 'auto';
    body.style.scrollBehavior = 'auto';
  }

  initialBody() {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    html.style.overflow = 'visible';
    body.style.overflow = 'visible';
    html.style.height = 'auto';
    body.style.height = 'auto';
    window.scrollTo(0, this.state.pageYpos);
    html.style.scrollBehavior = 'smooth';
    body.style.scrollBehavior = 'smooth';
  }

  renderAll() {
    if (this.state.isSpOpen) {
      this.header.dataset.state = 'sp-open';
      this.contents.dataset.state = 'active';
      this.opener.dataset.state = 'active';
      this.fixBody();
    } else {
      delete this.header.dataset.state;
      delete this.contents.dataset.state;
      delete this.opener.dataset.state;
      this.initialBody();
    }
  }
}
