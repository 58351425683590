import colorset from './colorset';

class FlowerParam {
  constructor({ elm }) {
    this.elm = elm;
    this.type = this.elm.dataset.type;
    this.state = this.elm.dataset.state;
    this.config = {
      types: ['kouyou', 'yellow', 'purple', 'dark_pink', 'salmon_pink'],
      states: ['disabled', 'end', 'lv0', 'lv1', 'lv2', 'lv3', 'lv4', 'lv5'],
    };
    const isType = this.config.types.find((type) => type === this.type);
    const isState = this.config.states.find((state) => state === this.state);
    if (!(isType && isState)) throw Error('[FowerParam Error] data属性の指定に誤りがあります');

    // カラーセットはtype名と同じものが割り当てられるが、
    // stateが特別な場合、特殊カラーに上書きする
    this.colors = colorset[this.type];
    if (this.state === 'disabled') this.colors = colorset['disabled'];
    if (this.state === 'end') this.colors = colorset['end'];

    this.enableColorLenConfig = {
      disabled: this.colors.length,
      end: this.colors.length,
      lv0: 0,
      lv1: Math.round(this.colors.length * (1 / 5)),
      lv2: Math.round(this.colors.length * (2 / 5)),
      lv3: Math.round(this.colors.length * (3 / 5)),
      lv4: Math.round(this.colors.length * (4 / 5)),
      lv5: this.colors.length,
    };

    this.enableColorLen = this.enableColorLenConfig[this.state];

    this.create();
  }

  create() {
    const paramWrap = document.createElement('div');
    paramWrap.style.display = 'flex';
    paramWrap.style.justifyContent = 'space-between';

    this.colors.forEach((color, index) => {
      const paramItem = document.createElement('span');
      // 配置にはflexのspace-betweenを利用するため5はスペースを作るための加算
      paramItem.style.border = `1px solid #F0F0F0`;
      paramItem.style.borderRadius = '0.125rem';
      paramItem.style.width = `${100 / (this.colors.length + 5)}%`;
      paramItem.style.height = '32px';
      const noColor = 'white';

      if (index <= this.enableColorLen - 1) {
        paramItem.style.backgroundColor = color;
      } else {
        paramItem.style.backgroundColor = noColor;
      }

      paramWrap.append(paramItem);
    });

    this.elm.append(paramWrap);
  }
}

export default FlowerParam;
