class checkAgreeBtn {
  constructor({ elm }) {
    this.elm = elm;
    this.checkBtn = this.elm.querySelector('input');
    this.submitBtn = this.elm.nextElementSibling;
    this.checkBtn.addEventListener('click', () => {
      if (this.checkBtn.checked === true) {
        this.submitBtn.disabled = false;
      } else {
        this.submitBtn.disabled = true;
      }
    });
  }
}

export default checkAgreeBtn;
