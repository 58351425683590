class CheckboxGroup {
  constructor({ parentElm, parentSelectorName, app }) {
    this.parentElm = parentElm;
    this.app = app;
    this.groupId = this.parentElm.dataset.groupId;
    this.childElms = document.querySelectorAll(
      `[data-group-id="${this.groupId}"]:not(${parentSelectorName})`
    );

    this.parentElm.addEventListener("click", () => {
      this.updateChildrenState();
    });

    this.childElms.forEach((elm) => {
      elm.addEventListener("click", () => {
        this.updateParentCheckState();
      });
    });
  }

  activeParent() {
    this.parentElm.checked = true;
  }

  inactiveParent() {
    this.parentElm.checked = false;
  }

  activeChildren() {
    this.childElms.forEach((elm) => {
      elm.checked = true;
    });
  }

  inactiveChildren() {
    this.childElms.forEach((elm) => {
      elm.checked = false;
    });
  }

  updateChildrenState() {
    if (this.parentElm.checked) this.activeChildren();
    if (!this.parentElm.checked) this.inactiveChildren();
  }

  updateParentCheckState() {
    const hasUncheckedChildMember = () =>
      Array.from(this.childElms).find((elm) => elm.checked === false);

    if (hasUncheckedChildMember()) this.inactiveParent();
    if (!hasUncheckedChildMember()) this.activeParent();
  }
}

export default CheckboxGroup;
