class ImagemapResizer {
  constructor({ app, imagemap, baseMapImg }) {
    this.app = app;
    this.imagemap = imagemap;
    this.baseMapImg = baseMapImg;

    this.originW = Number(this.imagemap.dataset.originW);
    this.originH = Number(this.imagemap.dataset.originH);

    this.areas = this.imagemap.querySelectorAll('area');

    this.areaCoordsArrs = [];
    this.areas.forEach((area) => {
      const coords = area.coords
        .replace(/ /g, '')
        .split(',')
        .map((n) => Number(n));
      this.areaCoordsArrs.push(coords);
    });

    // this.area = this.imagemap.querySelector('area');
    // this.areaCoordsArr = this.area.coords
    //   .replace(/ /g, '')
    //   .split(',')
    //   .map((n) => Number(n));
  }

  render() {
    const w = this.baseMapImg.getBoundingClientRect().width;
    const h = this.baseMapImg.getBoundingClientRect().height;
    const ratioW = w / this.originW;
    const ratioH = h / this.originH;

    this.areas.forEach((area, index) => {
      const areaNode = area;
      areaNode.coords = this.areaCoordsArrs[index]
        .map((n, i) => {
          if (i % 2 === 0) return Math.floor(n * ratioW);
          return Math.floor(n * ratioH);
        })
        .toString();
    });
  }
}

export default ImagemapResizer;
