import Swiper from 'swiper/bundle';

class RecommendContentsPopup {
  constructor({ elm, swiperElm, handle, carouselPrev, carouselNext, state }) {
    this.elm = elm;
    this.handle = handle;
    this.carouselPrev = carouselPrev;
    this.carouselNext = carouselNext;
    this.elmOffset = 12;

    this.state = {
      isOpen: state.isOpen,
    };

    this.swiper = new Swiper(swiperElm, {
      slidesPerView: 1.6,
      spaceBetween: 16,
      loop: true,
      centeredSlides: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
      navigation: {
        nextEl: this.carouselNext,
        prevEl: this.carouselPrev,
      },
    });

    const changeCloseDesign = () => {
      elm.style.bottom = `-${elm.clientHeight - this.elmOffset}px`;

      console.log(handle.querySelector('i'));
      handle.querySelector('i').classList.add('--down');
    };

    const changeOpenDesgin = () => {
      elm.style.bottom = 0;
      handle.querySelector('i').classList.remove('--down');
    };

    this.handle.addEventListener('click', () => {
      if (this.state.isOpen) {
        changeCloseDesign();
      } else {
        changeOpenDesgin();
      }
      this.state.isOpen = !this.state.isOpen;
    });

    window.addEventListener('resize', () => {
      changeCloseDesign();
      this.state.isOpen = false;
    });
  }
}

export default RecommendContentsPopup;
