// IndexMarkersMap
//
// Version: v 0.1.0
// Written: Masaaki Sumimoto

class IndexMarkersMap {
  constructor({ elm }) {
    this.elm = elm;
    this.info = JSON.parse(elm.dataset.info);
    this.markerImage = this.elm.dataset.markerImage;

    // 結局fitboundsするので仮の値
    this.mapCenter = { lat: 34.734142369686474, lng: 136.51017902460762 }; // tmp
    this.zoom = 12; // tmp

    const getInfowindowTags = (info) =>
      `
        <div>
          <div><a href="${info.permalink}" class="text-decoration-none text-dark" target="_blank">- 詳細ページへ<span class="ps-2" aria-hidden="true"><i class="fa-solid fa-arrow-up-right-from-square"></i></span></a><div>
          <div><a href="${window.location.pathname}#${info.page_fragment}" class="text-decoration-none text-dark">- ページ内の情報へ<span class="ps-2" aria-hidden="true"><i class="fa-solid fa-arrow-down"></i></span></a><div>
        </div>
      `;

    this.map = new window.google.maps.Map(this.elm, {
      center: this.mapCenter,
      zoom: this.zoom,
    });
    this.markers = [];
    this.info.forEach((markerInfo) => {
      this.markers.push(
        new window.google.maps.Marker({
          position: { lat: markerInfo.lat, lng: markerInfo.lng },
          map: this.map,
          icon: this.markerImage,
          label: {
            text: markerInfo.position,
            color: '#20A793',
            fontSize: '16px',
            fontWeight: 'bold',
            className: 'index-markers-map-marker-txt',
          },
          optimized: true,
        })
      );
    });
    this.infowindow = new window.google.maps.InfoWindow();

    this.markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        this.infowindow.setContent(getInfowindowTags(this.info[i]));
        this.infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });
    });

    this.init();
  }

  init() {
    this.map.fitBounds(
      this.info.reduce(
        (bounds, item) => bounds.extend({ lat: item.lat, lng: item.lng }),
        new window.google.maps.LatLngBounds()
      )
    );
  }
}

export default IndexMarkersMap;
