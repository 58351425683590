import { Modal } from 'bootstrap';

class MovieListItem {
  constructor({ cardElm }) {
    this.elm = cardElm;
    this.contentElm = this.elm.querySelector('.movie-list-item__content');
    this.modalElm = this.elm.querySelector('.modal');
    this.applyBtn = this.elm.querySelector('.modal-apply-btn');
    this.modalIFrameElm = this.modalElm.querySelector('iframe');
    this.bsModal = new Modal(this.modalElm);
    this.movieId = this.elm.getAttribute('data-movie-id')
    this.youTubeId = this.elm.getAttribute('data-youtube-id');
    this.applyLinkUrl = this.applyBtn.getAttribute('data-base-url');

    const showModal = () => {
      this.bsModal.show();
      this.modalIFrameElm.src = `https://www.youtube.com/embed/${this.youTubeId}`;
    };

    const sendAnalyticsData = () => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `/media/movie/${this.movieId}`, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            console.log(response);
          } else {
            console.error("アクセス集計でエラーが発生しました");
          }
        }
      };
      xhr.send();
    };

    const stopMovie = () => {
      this.modalIFrameElm.src = ``;
    }

    const openApplyPage = () => {
      window.location.href = `${this.applyLinkUrl}?movie_id=${this.movieId}`;
    };

    this.contentElm.addEventListener('click', () => {
      showModal();
      sendAnalyticsData();
    });

    this.applyBtn.addEventListener('click', () => {
      openApplyPage();
    });

    this.modalElm.addEventListener('hide.bs.modal', () => {
      stopMovie();
    })
  }
}

if (document.querySelectorAll('.movie-list-item').length) {
  document.querySelectorAll('.movie-list-item').forEach((cardElm) => {
    new MovieListItem({ cardElm });
  });
}

if (document.querySelectorAll('.media-cart-btns').length){
  window.addEventListener('pageshow', () => {
    const entries = performance.getEntriesByType("navigation");
    entries.forEach(function (entry) {
      if (entry.type === "back_forward") {
        window.location.reload();
      }
    });
  });
}
