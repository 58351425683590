// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  if ($('#recommend_feature_page').length) {
    // 2022.12.20 ooshima 後日非同期で表示する対応を行う予定。現状コメントアウト
    // $.ajax({
    //   url: '/feature_page_ai_recommend',
    //   beforeSend(xhr) {
    //     xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    //   },
    //   type: 'GET',
    //   timeout: 5000,
    // });
  }
});
