// SwiperPhoto
//

import Swiper from 'swiper/bundle';

class SwiperPhoto {
  constructor({ elm }) {
    this.swiper = new Swiper(elm, {
      slidesPerView: 2,
      spaceBetween: 16,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 3.8,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 4.8,
          spaceBetween: 24,
        },
      },
      navigation: {
        nextEl: elm.querySelector("[data-role='photo-swiper-next']"),
        prevEl: elm.querySelector("[data-role='photo-swiper-prev']"),
      },
    });
  }
}

export default SwiperPhoto;
