$(document).ready(function () {
  var flug = false;
  // 閉じる
  $('.accordion-content').slideToggle();

  $('.accordion-button').on('click', function () {
    if (flug) {
      $(this).removeClass('is-open');
      $('.accordion-content').slideUp();
      $(this).text('もっと見る');
      flug = false;
    } else {
      $(this).addClass('is-open');
      $('.accordion-content').slideDown();
      $(this).text('閉じる');
      flug = true;
    }
  });
});
