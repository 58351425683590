// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

function lazyLoadHTML(outputIdSelector, ajaxURL) {
  if ($(outputIdSelector).length) {
    $.ajax({
      url: ajaxURL,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }
}

function onClickLazyLoadHTML(buttonIdSelector, outputIdSelector, ajaxURL){
  let isLoaded = false;
  $(buttonIdSelector).click(()=>{
    if (!isLoaded){
      lazyLoadHTML(outputIdSelector,ajaxURL);
      isLoaded = true;
    }
  });
}

let isTopPageLoaded = false;
$(window).on('load',()=>{
  $(window).scroll(() => {
    if (!isTopPageLoaded) {
      lazyLoadHTML('#top-pickup', '/top_mie_article');
      lazyLoadHTML('#daily-event', '/top_event');
      lazyLoadHTML('#ranking-report', '/top_ranking_report')
      lazyLoadHTML('#top-theme', '/top_pickup_mie');
      lazyLoadHTML('#instagram', '/top_instagram');
      isTopPageLoaded = true;
    }
  });
});

onClickLazyLoadHTML('#ranking-spot-btn', '#ranking-spot','/top_ranking_spot');
onClickLazyLoadHTML('#ranking-gourmet-btn', '#ranking-gourmet', '/top_ranking_gourmet');
onClickLazyLoadHTML('#ranking-event-btn', '#ranking-event', '/top_ranking_event');
onClickLazyLoadHTML('#ranking-activity-btn', '#ranking-activity', '/top_ranking_activity');
onClickLazyLoadHTML('#ranking-ai-btn', '#recommend_spot', '/top_ai_recommend');

// ブラウザ幅が広い時のみ読み込み時グロナビの位置をMV下にする
if (document.querySelector('.miepref-global-header--home') !== null) {
  let gnavFixed = () => {
    const globalHeader = document.querySelector('.miepref-global-header');
    const totopBtn = document.querySelector('.totop-btn--top');
    const globalHeaderHeight = 100;
    const windowHeight = window.innerHeight;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    if (scroll > windowHeight - globalHeaderHeight) {
      globalHeader.classList.remove('is-xl-absolute');
      totopBtn.classList.remove('is-mv-show');
    } else {
      globalHeader.classList.add('is-xl-absolute');
      totopBtn.classList.add('is-mv-show');
    }
  };
  window.addEventListener('load', gnavFixed);
  window.addEventListener('scroll', gnavFixed);
}
