// SwiperSingle
//

import Swiper from 'swiper/bundle';

class SwiperSingle {
  constructor({ elm }) {
    this.swiper = new Swiper(elm, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: elm.querySelector("[data-role='single-swiper-pagination']"),
        clickable: true,
      },
      navigation: {
        nextEl: elm.querySelector("[data-role='single-swiper-next']"),
        prevEl: elm.querySelector("[data-role='single-swiper-prev']"),
      },
    });
  }
}

export default SwiperSingle;
