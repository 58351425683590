// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="spot_area_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[areas_id_in][]"]:checked').length === $('input[name="q[areas_id_in][]"]').length - 1) {
      $('input[name="spot_area_id[all]"]').prop('checked', true);
    }
  }

  // 各カテゴリーのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchCategoryAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="spot_category_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if (
      $('input[name="q[categories_id_in][]"]:checked').length ===
      $('input[name="q[categories_id_in][]"]').length - 1
    ) {
      $('input[name="spot_category_id[all]"]').prop('checked', true);
    }
  }

  // 現在地取得成功時のみサーバに処理を送信する
  function successGetPosition(position) {
    const url = $('#sort_distance').attr('href');
    $('#sort_distance').attr('href', `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`);
    window.location.href = `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`;
  }

  function failGetPosition(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('位置情報の取得が許可されませんでした');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('電波状況などで位置情報が取得できませんでした');
        break;
      case error.TIMEOUT:
        alert('位置情報の取得にてタイムアウトが発生しました');
        break;
      case error.UNKNOWN_ERROR:
        alert('原因不明のエラーが発生しました');
        break;
    }
  }

  // 現在地に近いボタン押下時、現在地を取得する
  if ($('#sort_distance').length) {
    $('#sort_distance').click(function f() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successGetPosition, failGetPosition, options);
      } else {
        alert('ご使用中のブラウザは現在地検索に対応されておりません');
      }
      return false;
    });
  }

  if ($('#spot_search').length) {
    // エリア全て選択時、エリアの状態も変更する
    $('input[name="spot_area_id[all]"]').click((e) => {
      $('input[name="q[areas_id_in][]"]').prop('checked', e.target.checked);
    });

    // エリア選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[areas_id_in][]"]').click(() => {
      switchAreaAllCheckbox();
    });

    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="spot_category_id[all]"]').click((e) => {
      $('input[name="q[categories_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[categories_id_in][]"]').click(() => {
      switchCategoryAllCheckbox();
    });
  }
});

if (document.querySelector('.near-contents')) {
  let nearContentsSwiper = new Swiper(".near-contents [data-role='cmn-swiper']", {
    slidesPerView: 2,
    spaceBetween: 16,
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
    navigation: {
      nextEl: ".near-contents [data-role='cmn-swiper-next']",
      prevEl: ".near-contents [data-role='cmn-swiper-prev']",
    },
  });
}

// 行きたいボタン押下時の吹き出し
if (document.querySelector('.myplan-balloon')) {
  const balloonCnt = document.querySelector('.myplan-balloon');
  const addBtn = document.querySelector("[data-target='favorite-spot-button.add']");
  const removeBtn = document.querySelector("[data-target='favorite-spot-button.remove']");
  const closeBtn = document.querySelector('.myplan-balloon__close');
  addBtn.addEventListener('click', function () {
    balloonCnt.classList.add('is-show');
  });
  removeBtn.addEventListener('click', function () {
    balloonCnt.classList.remove('is-show');
  });
  closeBtn.addEventListener('click', function () {
    balloonCnt.classList.remove('is-show');
  });
}
