// SwiperGallery
//
// メインのswiperとそれを操作するサムネイルのswiperの2種類を生成する
// そのためページ上にあるどれとどれがペアかを示してあげるためにdata-pairing-idの値を渡す必要がある

import Swiper from 'swiper/bundle';

class SwiperGallery {
  constructor({ pairingId }) {
    this.pairingId = pairingId;
    this.pics = document.querySelector(`[data-role="gallery-swiper"][data-pairing-id=${this.pairingId}]`);
    this.thumbs = document.querySelector(`[data-role="gallery-thumb-swiper"][data-pairing-id=${this.pairingId}]`);
    this.thumbItems = this.thumbs.querySelectorAll('[data-role="gallery-thumb-carousel-item"]');

    this.thumbsSwiper = new Swiper(this.thumbs, {
      slidesPerView: 4,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 32,
        },
      },
    });

    this.picSwiper = new Swiper(this.pics, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: this.pics.querySelector("[data-role='gallery-swiper-pagination']"),
        clickable: true,
      },
      navigation: {
        nextEl: this.pics.querySelector("[data-role='gallery-swiper-next']"),
        prevEl: this.pics.querySelector("[data-role='gallery-swiper-prev']"),
      },
      thumbs: {
        swiper: this.thumbsSwiper,
      },
    });
  }
}

export default SwiperGallery;
