export default {
  kouyou: [
    '#54EA40',
    '#63E552',
    '#90E762',
    '#B6E971',
    '#D2EB7E',
    '#DEEB7E',
    '#EBE77E',
    '#EDE98A',
    '#F0F055',
    '#F0F05B',
    '#F5EE34',
    '#FFF250',
    '#FFEC07',
    '#FCEC28',
    '#FFD95C',
    '#FDC747',
    '#FFBB40',
    '#FD9D62',
    '#FF8B43',
    '#F07331',
    '#DC493D',
    '#D93D29',
    '#D52912',
  ],
  yellow: [
    '#FFF6BE',
    '#FEEFAE',
    '#FEF2B6',
    '#FEEBA6',
    '#FEE89E',
    '#FEE496',
    '#FDE18E',
    '#FDDE86',
    '#FDDA7E',
    '#FDD776',
    '#FDD36E',
    '#FDD066',
    '#FCCD5E',
    '#FCC956',
    '#FCC64E',
    '#FCC246',
    '#FCBF3E',
    '#FBBC36',
    '#FBB82E',
    '#FBB526',
    '#FBB11E',
    '#FBAE16',
    '#FBAB0F',
  ],
  purple: [
    '#fdf9ff',
    '#fbf4ff',
    '#f9eeff',
    '#f6e8ff',
    '#f4e2ff',
    '#f2ddfe',
    '#f0d7fe',
    '#eed1fe',
    '#eccbfe',
    '#eac6fe',
    '#e8c0fe',
    '#e5bafe',
    '#e3b4fe',
    '#e1affe',
    '#dfa9fe',
    '#dda3fe',
    '#db9dfe',
    '#d998fd',
    '#d48cfd',
    '#d792fd',
    '#d286fd',
    '#d081fd',
    '#ce7bfd',
  ],
  dark_pink: [
    '#fef6fa',
    '#fceef6',
    '#fbe5f1',
    '#f9dded',
    '#f8d4e8',
    '#f6cce3',
    '#f5c3df',
    '#f4bada',
    '#f2b2d6',
    '#f1a9d1',
    '#efa1cc',
    '#ee98c8',
    '#ec90c3',
    '#eb87be',
    '#e97fba',
    '#e876b5',
    '#e76db1',
    '#e565ac',
    '#e45ca7',
    '#de3a95',
  ],
  salmon_pink: [
    '#FFFFFF',
    '#FFF8F8',
    '#FFF1F1',
    '#FFEAEA',
    '#FFE3E3',
    '#FFDCDC',
    '#FFD5D5',
    '#FFCECE',
    '#FFC7C7',
    '#FFC0C0',
    '#FFB9B9',
    '#FFB2B2',
    '#FFABAB',
    '#FFA4A4',
    '#FF9D9D',
    '#FF9696',
    '#FF8F8F',
    '#FF8888',
    '#FF8181',
    '#FF7A7A',
    '#FF7373',
    '#FF6C6C',
    '#FF6666',
  ],
  disabled: [
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
    '#DDDDDD',
  ],
  end: [
    '#F8F8F8',
    '#F2F2F2',
    '#F0F0F0',
    '#EEEEEE',
    '#EAEAEA',
    '#E5E5E5',
    '#E0E0E0',
    '#D0D0D0',
    '#D5D5D5',
    '#C7C7C7',
    '#B9B9B9',
    '#A3A3A3',
    '#A4A4A4',
    '#9D9D9D',
    '#979797',
    '#8E8E8E',
    '#898989',
    '#7A7A7A',
    '#737373',
    '#525252',
    '#404040',
    '#333333',
    '#050505',
  ],
};
