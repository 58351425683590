class ContentCloser {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;

    this.elm.addEventListener('click', () => {
      this.app.state.isContentActive = false;
      this.app.state.activeContentId = null;
      this.app.render();

      return false;
    });
  }
}

export default ContentCloser;
