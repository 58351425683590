class Area {
  constructor({ elm, app, scrollTarget }) {
    this.elm = elm;
    this.app = app;
    this.id = this.elm.dataset.areaId;
    this.scrollTarget = scrollTarget;

    this.elm.addEventListener('click', (e) => {
      e.preventDefault();
      this.app.state.activeAreaId = this.id;
      this.app.render();

      if (this.app.state.isSp) this.scrollTarget.scrollIntoView();

      return false;
    });
  }

  render() {
    const isActiveArea = this.app.state.activeAreaId === this.id;

    if (isActiveArea) this.elm.dataset.state = 'active';
    if (!isActiveArea) delete this.elm.dataset.state;
  }
}

export default Area;
